import React from 'react';
import Slider from "react-slick";
import css from './ListingGallery.module.css';
import { ResponsiveImage } from '../../../components';

function ListingGallery(props) {
    const { listing, variantPrefix } = props;
    const images = listing?.images || [];

    let settings = {
        dots: true,
        speed: 1000,
        infinite: false,
        slidesToScroll: 3,
        slidesToShow: 4.5,
    };

    return (
        <div className={css.listingGrid}>
            <Slider {...settings}>
                {images.map((item, i) => {
                    const { url = "" } = item?.attributes?.variants?.[variantPrefix];
                    return (
                        <div
                            className={css.galleryImage}
                            key={i}
                        >
                            <ResponsiveImage
                                rootClassName={css.avatarImage}
                                alt={'Gallery'}
                                image={item}
                                variants={['listing-card-2x']}
                            />
                        </div>
                    )
                })}
            </Slider>
        </div>
    )
}

export default ListingGallery